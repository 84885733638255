import PageHead from "../../Components/PageHead";

const AboutUs = () => {
    const details = 'GreenWebLab IT specialists with business information to promote changes and help clients deliver results. Learn more about our missions, vision and goals.'
    return (
        <>
            <PageHead details={details} title="About Us" buttonTitle={'CONTACT US'} buttonHref={'/ContactUs'}/>

            <div className="aboutUsSection2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 aboutUsSection2_image">
                            <img className="image-fluid" src="img/aboutUs/greenweblab-about-us-768x512.jpeg" alt="about-us" />
                        </div>

                        <div className="col-md-6 col-sm-12 mt-2">
                            <div className="aboutUsSection2_title text-center">
                            About Us - GreenWebLab
                            </div>
                            <p>
                            As a tech company, we continue to strive for new, simpler ways to help companies grow. Today, we spare no effort to extend our knowledge-base and improve the level of expertise we provide to our clients.
                            </p>
                            <p>
                            We architect and engineer scalable and high-performing software solutions to meet the business challenges of our clients. 
                            </p>
                            <p>
                            Meet our team, the people behind Tarika who bring along their extensive experience and specialization to achieve what they envisioned. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
        
    )
    };
    export default AboutUs;