// import './App.css';
import {Routes, Route} from "react-router-dom";
import HomePage from './pages/HomePage/homePage';
import NavbarMenu from './layout/NavbarMenu'
import './styles/style.css'
import Footer from './layout/Footer';
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  return (
    <>
      <NavbarMenu />
      <Routes>
        <Route path="/" element={<HomePage />} exact/>
        <Route path="/about" element={<AboutUs />} exact/>
        <Route path="/services" element={<Services />} exact/>
        <Route path="/ContactUs" element={<ContactUs />} exact/>
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} exact/>
      </Routes>
      <Footer />
    </>

  );
}

export default App;
