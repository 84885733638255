import clsx from "clsx";

const TabItemComponent = ({
    label, value, selectedValue, onChange,
  }) => {
  
    return (
      <div
        className={
        clsx(selectedValue?.value === value ? 'ServiceActiveClass' : 'ServiceNotActiveClass',
          'tabComponent col text-center py-2 px-6')
  }
        onClick={() => onChange({label, value})}
      >
        {label}
      </div>
    );
  };
  
  const TabComponent = ({data, onChange, selectedValue}) => (
    <div className="row justify-content-around">
      {data.map((item) => (
        <TabItemComponent
          key={item.value}
          onChange={onChange}
          selectedValue={selectedValue}
          {...item}
        />
      ))}
    </div>
  );
  
  export default TabComponent;