import React,{useState} from 'react';
import {NavLink} from 'react-router-dom';
import * as Icon from "react-bootstrap-icons";

const NavbarMenu = () => {
    const [isMenu, setIsMenu] = useState(false);
    const [isResponsiveClose, setResponsiveClose] = useState(false);
    const toggleClass = () => {
      setIsMenu(isMenu === false ? true : false);
      setResponsiveClose(isResponsiveClose === false ? true : false);
  };

    let boxClass = ["align-items-center main-menu menu-right menuq1"];
    if(isMenu) {
        boxClass.push('menuq2');
    }else{
        boxClass.push(' ');
    }

    const [isMenuSubMenu, setMenuSubMenu] = useState(false);
      
    const toggleSubmenu = () => {
      setMenuSubMenu(isMenuSubMenu === false ? true : false);
    };
    
    let boxClassSubMenu = ["sub__menus"];
    if(isMenuSubMenu) {
        boxClassSubMenu.push('sub__menus__Active');
    }else {
        boxClassSubMenu.push('');
    }

    return (

        <>
            <div className="top-bar">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <a href="mailto:info@greenweblab.com" ><Icon.EnvelopeFill /><span className="d-none mx-2 d-md-inline-block">info@greenweblab.com</span></a>
                                <span className="mx-md-2 d-inline-block"></span>
                                <a href="tel:+918799160191"><Icon.Telephone /> <span className="d-none d-md-inline-block">+91 8799160191 (IN)</span></a>
                            </div>
                            <div className='icons'>
                                <NavLink target="_blank" className="mx-2" to="/" title="skype"><Icon.Skype /></NavLink>
                                <NavLink target="_blank" className="mx-2" to="/" title="Instagram"><Icon.Instagram /></NavLink>
                                <NavLink target="_blank" className="mx-2" to="/" title="Facebook"><Icon.Facebook /></NavLink>
                                <NavLink target="_blank" className="mx-2" to="/" title="Twitter "><Icon.Twitter /></NavLink>
                                <NavLink target="_blank" className="mx-2" to="/" title="Linkedin"><Icon.Linkedin /></NavLink>
                            </div>
                    </div>
                </div>
            </div>
        
            <header className="header__middle bg-white">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">

                        {/* Add Logo  */}
                        <div className="header__middle__logo">
                            <NavLink exact to="/" className="d-flex align-items-center text-decoration-none">
                                <img src="img/logo/Green_logo.svg" className='navbar_logo_rotate' alt="logo" width={50}/> 
                                <span className= "logo_text h3"> GreenWebLab</span>
                            </NavLink>
                        </div>

                        <div className="header__middle__menus">
                            <nav className="main-nav " >

                            {/* Responsive Menu Button */}
                            {isResponsiveClose === true ? <> 
                                <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <Icon.XCircle />   </span>
                            </> : <> 
                                <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <Icon.TextRight />   </span>
                            </>}
                                <ul className={boxClass.join(' ')}>
                                    <li  className="menu-item" >
                                        <NavLink exact activeClassName='is-active' onClick={toggleClass} to={`/`} className='d-flex justify-content-center align-items-center'> <Icon.HouseFill /> <span>Home</span> </NavLink> 
                                    </li>
                                    <li className="menu-item " ><NavLink exact onClick={toggleClass} activeClassName='is-active' to="/about" className='d-flex justify-content-center align-items-center'><Icon.BriefcaseFill /><span>About Us</span></NavLink> </li>
                                    <li onClick={toggleSubmenu} className="menu-item sub__menus__arrows" >
                                        <NavLink exact to="/services" className='d-flex justify-content-center align-items-center' activeClassName='is-active'>
                                            <Icon.Gear className='service_icon' />
                                            <span>Services</span>
                                            {/* <Icon.ChevronDown /> */}
                                         </NavLink>
                                        {/* <ul className={boxClassSubMenu.join(' ')} > 
                                            <li className='sub_menu'> <NavLink onClick={toggleClass} activeClassName='is-active'  to={`/Online`}> Web Development </NavLink> </li>
                                            <li className='sub_menu'><NavLink onClick={toggleClass} activeClassName='is-active' to={`/Offline`}> Apps Development </NavLink> </li>
                                            <li className='sub_menu'><NavLink onClick={toggleClass} activeClassName='is-active' to={`/Offline`}> Graphic Design </NavLink> </li>
                                            <li className='sub_menu'><NavLink onClick={toggleClass} activeClassName='is-active' to={`/Offline`}> Database Services </NavLink> </li>
                                            <li className='sub_menu'><NavLink onClick={toggleClass} activeClassName='is-active' to={`/Offline`}> Cloud Services </NavLink> </li>
                                        </ul> */}
                                    </li>
                                    <li className="menu-item"><NavLink exact onClick={toggleClass} activeClassName='is-active' to='/ContactUs' className='d-flex justify-content-center align-items-center'> <Icon.JournalBookmarkFill /> <span>Contact</span></NavLink> </li>
                                    <li className="menu-item "><NavLink exact onClick={toggleClass} to='/ContactUs' activeClassName='is-active' className="get-quote">Get Started</NavLink></li>
                                </ul>
                            </nav>     
                        </div>   

                    </div>
                </div>
            </header>
        </>
    )
};

export default NavbarMenu;
