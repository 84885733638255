import { NavLink } from "react-router-dom";

const SoftwareAndMobileApp = () => {

    return (
        <>
            <div className= 'SM_app container-fluid p-0'>
                <div className = "SM_app_title">
                    <span>Let's Check Our Software & <br/> Mobile App Services.</span>
                </div>
                <hr />
                <div className = "SM_app_web_app">
                    <div className="web_app_service text-center">
                        <span>Web Applications</span>
                    </div>
                    <p className="web_text"> Your company will be able to solve its operational issues, increase workflow effectiveness, and save time and money by hiring the correct software developer to build a custom web application.</p>
                </div>

                <div className="web_app_section_1">
                    <div className="row align-items-center">
                        <div className="col-md-6 p-5 ">
                            <div className="details">
                                <div className="question my-4">
                                    <span>Why Choose?</span>
                                </div>
                                <div className="heading">
                                    <p><span>GREENWEBLAB</span> for Web Development</p>
                                </div>
                                <div className="describe">
                                    <p>We offer web application development services in a way that eliminates the need for you to consider working with a different company.</p>
                                </div>
                                <div className="contact_button">
                                    <button className="buttons"><NavLink to="/ContactUs">CONTACT US</NavLink></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 p-3 ">
                            <div className="image">
                                <img src="/img/Services/web-app.png" alt="web-app" />
                            </div>
                        </div>
                    </div>

                </div>

                <div className="web_app_section_2">
                    <div className="heading">
                        <span>Web application Technologies & Frameworks we Use?</span>
                    </div>
                    <div className="description">
                        <p>To keep up with the continuously changing web technologies and frameworks, we go above and beyond. In order to streamline complex business procedures, we provide our clients with adaptive web apps created with Php, Yii2 Framework, Laravel, ReactJs, Node.js, NestJs, PostgreSQL, MySQL,MongoDB.</p>
                    </div>
                    <div className="web_app_section_2_1">
                        <div className="row align-items-center">
                            <div className="col-sm-6 p-3">
                                <div className="image">
                                    <img className="image-fluid" src="/img/Services/web-service.jpg" alt="web-service" />
                                </div>
                            </div>
                            <div className="col-sm-6 p-2">
                                <div className="row">
                                    <div className="col">
                                        <div className="icon_box">
                                            <img width= "25%" src="/img/Services/Yii-service.png" alt="react" />
                                            <img width= "25%" src="/img/Services/php.svg" alt="react" />
                                            <img width= "25%" src="/img/Services/react.svg" alt="react" />
                                            <img width= "25%" src="/img/Services/nodejs-icon.svg" alt="react" />
                                            <img width= "25%" src="/img/Services/laravel-service.svg" alt="react" />
                                            <img width= "25%" src="/img/Services/magento-service.svg" alt="react" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>

                <div className = "SM_app_mobile_app">
                    <div className="mobile_app_service text-center">
                        <span>Mobile applications development services</span>
                    </div>
                    <p className="mobile_text"> 
                        Your company will be able to solve its operational issues, increase workflow effectiveness, and save time and money by hiring the correct software developer to build a custom web application.
                    </p>
                    <div className="mobile_app_section_1">
                        <div className="row align-items-center">
                            <div className="col-md-6 p-3 ">
                                <div className="image">
                                    <img className="image-fluid" src="/img/Services/mobile-service.png" alt="mobile-service" />
                                </div>
                            </div>
                            <div className="col-md-6 p-3 ">
                                <div className="details">
                                    <div className="heading">
                                        <p>
                                            We are an industry leading IT solutions company with top of the line mobile applications development company.
                                        </p>
                                    </div>
                                    <div className="description">
                                        <p>
                                            If you’re looking to leverage modern technology such as mobile and web for your businesses, Our experienced developers are at your fingertips. We provide ios and Android mobile applications development services
                                        </p>
                                    </div>
                                        <div className="contact_button">
                                        <button className="buttons"><NavLink to="/ContactUs">CONTACT US</NavLink></button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <hr />

                <div className = "SM_UI">
                    <div className="UI_service">
                        <div className="UI_title text-center">
                            <span>UI/UX Design Services!</span>
                        </div>
                        <div className="UI_text text-center">
                            <p> 
                                We assist companies in creating appealing and useful software. Discover what you require for your idea by looking through our range of UI/UX design services.
                            </p>
                        </div>
                    </div>
                    <div className="UI_section_1 container-fluid">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="ui_card">
                                    <div className="card_title d-flex align-items-center">
                                        <div className="card_icon">
                                            <img width='60' src="/img/Services/uiIcon/icon_1.svg" alt="icon" />
                                        </div>
                                        <div className="card_heading">
                                            <span>Prototype</span>
                                        </div>
                                    </div>
                                    <div className="card_description">
                                        <p>
                                            To demonstrate the behavior of the application and how it would function in practice, we developed a prototype. We create completely interactive prototypes to help you understand how your project will work.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="ui_card">
                                    <div className="card_title d-flex align-items-center">
                                        <div className="card_icon">
                                            <img width='60' src="/img/Services/uiIcon/icon_2.svg" alt="icon" />
                                        </div>
                                        <div className="card_heading">
                                            <span>Wireframes</span>
                                        </div>
                                    </div>
                                    <div className="card_description">
                                        <p>
                                            We create wireframes to show you the content, structure, and functionality that will be present on the pages of your app or program. This gives you an overview of a mobile or web app.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="ui_card">
                                    <div className="card_title d-flex align-items-center">
                                        <div className="card_icon">
                                            <img width='60' src="/img/Services/uiIcon/icon_3.svg" alt="icon" />
                                        </div>
                                        <div className="card_heading">
                                            <span>Mobile and Web User Interface Design</span>
                                        </div>
                                    </div>
                                    <div className="card_description">
                                        <p>
                                            To transform your ideas and thought processes into a functional design that is clear and understandable, our team of UI/UX design experts will make every attempt. We create and offer precisely what a design requires for and makes sense.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="ui_card">
                                    <div className="card_title d-flex align-items-center">
                                        <div className="card_icon">
                                            <img width='60' src="/img/Services/uiIcon/icon_4.svg" alt="icon" />
                                        </div>
                                        <div className="card_heading">
                                            <span>Cross-Platform Compatibility</span>
                                        </div>
                                    </div>
                                    <div className="card_description">
                                        <p>
                                            We create responsive and cross-platform compatible designs, focusing on the fundamentals of UI/UX design and functionality implementation, to guarantee that it functions on various platforms.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="ui_card">
                                    <div className="card_title d-flex align-items-center">
                                        <div className="card_icon">
                                            <img width='60' src="/img/Services/uiIcon/icon_5.svg" alt="icon" />
                                        </div>
                                        <div className="card_heading">
                                            <span>Technical Design</span>
                                        </div>
                                    </div>
                                    <div className="card_description">
                                        <p>
                                            Our team can also produce a technical design to help our clients comprehend the project’s technical workflow. In technical design, we specify the technological stack for each app’s component, as well as any third-party integrations and services.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="ui_card">
                                    <div className="card_title d-flex align-items-center">
                                        <div className="card_icon">
                                            <img width='60' src="/img/Services/uiIcon/icon_6.svg" alt="icon" />
                                        </div>
                                        <div className="card_heading">
                                            <span>Software Redesign</span>
                                        </div>
                                    </div>
                                    <div className="card_description">
                                        <p>
                                            We can assist you by redesigning the entire website or application if you already have one but it does not look appealing or professional. We build your program to seem appealing and attractive after evaluating your app and getting to know the users.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

            </div>
        </>
    )

};

export default SoftwareAndMobileApp;