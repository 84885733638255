import { NavLink } from "react-router-dom";

const ItSolutionsServices = () => {
    return (
        <>
            <div className= 'itSolutionsServices container-fluid'>
                <div className = "itSolutionsServices_title">
                    <span>Let's Check Our IT Solution & <br/> Services.</span>
                </div>
                <hr />
                <div className = "itSolutionsServices_remote_tech">
                    <div className="remote_tech_service text-center">
                        <span>Remote Tech Support</span>
                    </div>
                    <div className="row remote_tech align-items-center">
                        <div className="col-sm-6 px-4">
                            <div className="remote_tech_img m-3">
                                <img className="image-fluid" src="img/Services/remote-service.png" alt="remote_tech_img" />
                            </div>
                        </div>
                        <div className="col-sm-6 px-4">
                            <div className="remote_tech_text">
                                <div className="remote_tech_text_title">
                                    <p>Expert Remote Tech <span>Support</span></p>
                                </div>
                                <p className="remote_tech_text_detail">Businesses may use information technology effectively to improve services and streamline operations. When that instrument is utilized to the fullest, it develops a competitive edge that benefits your company’s success. We make sure that tool remains a benefit by offering quick technical assistance, pro-active upkeep, and ongoing upgrades.</p>
                            </div>
                            <div className="remote_tech_details d-none d-lg-block">
                                <div className="remote_tech_details_1 d-flex    ">
                                    <div className="remote_tech_icons">
                                        <span>
                                            <NavLink to="/Services">
                                                <img src="img/Services/icon1.svg" alt="" />
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="remote_tech_details_1_text">
                                        <span>
                                        <NavLink to="/Services">We provides help to businesses of any scale in the metro Detroit area and worldwide.</NavLink>
                                        </span>
                                    </div>
                                </div>

                                <div className="remote_tech_details_1 d-flex    ">
                                    <div className="remote_tech_icons">
                                        <span>
                                            <NavLink to="/Services">
                                                <img src="img/Services/icon1.svg" alt="" />
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="remote_tech_details_1_text">
                                        <span>
                                        <NavLink to="/Services">We place a priority on technology availability, performance, and security and are committed to providing quick, dependable IT help.</NavLink>
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-12 mx-5">
                            <div className="remote_tech_details d-lg-none d-block">
                                <div className="remote_tech_details_1 d-flex    ">
                                    <div className="remote_tech_icons">
                                        <span>
                                            <NavLink to="/Services">
                                                <img src="img/Services/icon1.svg" alt="" />
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="remote_tech_details_1_text">
                                        <span>
                                        <NavLink to="/Services">We provides help to businesses of any scale in the metro Detroit area and worldwide.</NavLink>
                                        </span>
                                    </div>
                                </div>

                                <div className="remote_tech_details_1 d-flex    ">
                                    <div className="remote_tech_icons">
                                        <span>
                                            <NavLink to="/Services">
                                                <img src="img/Services/icon1.svg" alt="" />
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="remote_tech_details_1_text">
                                        <span>
                                        <NavLink to="/Services">We place a priority on technology availability, performance, and security and are committed to providing quick, dependable IT help.</NavLink>
                                        </span>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <hr />

                 <div className="manage_it_service">
                    <div className="title text-center">
                        <span>Managed It Services & Consulting</span>
                    </div>
                    <div className="manage_it_service_detail">
                        <div className="row align-items-center">
                            <div className="col-sm-6 px-5">
                                <div className="heading">
                                    <span>Does Your Business Need Information Technology Consulting Services?</span>
                                </div>
                                <div className="details">
                                    <span>A crucial area where an IT consultant may add value is by helping your company develop an IT strategic strategy or roadmap. An IT strategic plan often outlines your core business drivers, objectives, and goals as well as the steps necessary to reach them.</span>
                                </div>
                                <div className="buttons">
                                    <button className="btn"><NavLink to="/ContactUs">Get in touch for consulting</NavLink></button>
                                </div>
                            </div>
                            <div className="col-sm-6 p-4">
                                <div className="image text-center">
                                    <img className="image-fluid" src="/img/Services/manage-service.png" alt="" />
                                </div>
                                
                            </div>
                        </div>
                    </div>

                 </div>



            </div>
        </>
    )

};
export default ItSolutionsServices;