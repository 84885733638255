import { NavLink } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";

const Footer = () => {

    return (
        <>
    {/* start footerWrap  */}
            <footer className="main-footer ">
                <div className="footer-upper ">
                    <div className="container ">
                        <div className="row mr-3 ml-3">
                            <div className="big-column col-md-6 col-sm-12 col-xs-12 ">
                                <div className="row ">
                                    <div className="footer-column col-md-7 col-sm-6 col-xs-12 ">
                                        <div className="footer-widget about-widget ">
                                            <h2> We Are Here <br/> <span> Contact Us </span></h2>
                                                {/* <p>
                                                    D-52, Sumeru City Mall,<br/> Near Sudama Chowk,<br/> Mota Varachha, Surat-394101<br/> 
                                                </p> */}
                                                <p>
                                                    <a href="mailto:info@greenweblab.com" ><Icon.EnvelopeFill /><span className="mx-2">info@greenweblab.com</span></a>
                                                </p>
                                                <p>
                                                    <a href="tel:+918799160191"><Icon.Telephone /> <span className="mx-2">+91 8799160191 (IN)</span></a>
                                                </p>

                                            <ul className="social-icon-one ">
                                                <li><NavLink target="_blank " to="/"><span className="fa fa-facebook "></span></NavLink></li>
                                                <li><NavLink rel="nofollow " target="_blank " to="/"><span className="fa fa-instagram "></span></NavLink></li>
                                                <li><NavLink rel="nofollow " target="_blank " to="/"><span className="fa fa-twitter "></span></NavLink></li>
                                                <li><NavLink rel="nofollow " target="_blank " to="/"><span className="fa fa-linkedin "></span></NavLink></li>
                                                <li><NavLink rel="nofollow" target="_blank" to="/"> <span className="fa fa-skype "></span></NavLink></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="footer-column col-md-5 col-sm-6 col-xs-12 ">
                                        <div className="footer-widget links-widget ">
                                            <h2>Solutions</h2>
                                            <div className="widget-content ">
                                                <ul className="list ">
                                                    <li><NavLink to="/">Web Designing</NavLink></li>
                                                    <li><NavLink to="/">Business Process management</NavLink></li>
                                                    <li><NavLink to="/">E-commerce Solutions</NavLink></li>
                                                    <li><NavLink to="/">Enterprise Mobility Solutions</NavLink></li>
                                                    <li><NavLink to="/">Wordpress Development</NavLink></li>
                                                    <li><NavLink to="/">Drupal Development </NavLink></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="big-column col-md-6 col-sm-12 col-xs-12 ">
                                <div className="row">
                                    <div className="footer-column col-md-5 col-sm-6 col-xs-12 ">
                                        <div className="footer-widget links-widget ">
                                            <h2>Quick Links</h2>
                                            <div className="widget-content ">
                                                <ul className="list ">
                                                    <li><NavLink to="/about">About Us</NavLink></li>
                                                    <li><NavLink to="/services ">Services</NavLink></li>
                                                    <li><NavLink to="/ ">FAQs</NavLink></li>
                                                    <li><NavLink to="/ContactUs ">Contact</NavLink></li>
                                                    <li><NavLink to="/">Blog</NavLink></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="footer-column col-md-6 col-sm-6 col-xs-12 ">
                                        <div className="footer-widget address-widget ">
                                            <h2>Pay With Paypal</h2>
                                            <div className="widget-content ">
                                                <ul className="list-style-two ">
                                                    <li>
                                                        <NavLink rel="nofollow " target="_blank " to="/"><img src="img/Footer/Paypal.png" alt="Paypal"/></NavLink>
                                                    </li>
                                                </ul>
                                                <ul className="list-style-two ">
                                                    <li>
                                                        <NavLink to="/" target="_blank "><img src="img/Footer/Good-&-Firm.png" alt="Good-&-Firm"/></NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/" target="_blank "><img src="img/Footer/Seo-Futura.png " alt="Seo-Futura"/></NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/" target="_blank "><img src="img/Footer/clutch.png " alt="clutch"/></NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        {/* CopyRight */}
                <div className="footer-bottom ">
                    <div className="auto-container ">
                        <div className="copyright ">Copyright &copy; 2022 <NavLink to="/">GreenWebLab</NavLink>. All Rights Reserved.</div>
                        <ul className="copyright-links ">
                            <li><NavLink to="/ ">Sitemap</NavLink></li>
                            <li><NavLink to="/PrivacyPolicy ">Privacy Policy</NavLink></li>
                            <li><NavLink to="/">Terms And Conditions</NavLink></li>
                        </ul>
                    </div>
                </div>
            </footer>



        
        </>
    )

};

export default Footer;