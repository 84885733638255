import { NavLink } from "react-router-dom";

const HomePage = () => {
return (
    <>

        <div className="container">
            <div className="row align-items-center">
                <div className="text-center col-sm-6 col-12">
                    <img className="head_icon_rotate image-fluid" alt="logo" src = "img/banner/White.svg"  />
                </div>
                <div className="col-sm-6 col-12">
                    <h1 className="text-center display-2 head_icon_title head_icon_title_text">GreenWebLab</h1>
                    <p className="head_icon_title_button text-center "><NavLink to="/ContactUs"> Let's Start</NavLink></p>
                </div>
            </div>
        </div>
        {/* Why We Are?... */}
        <section className="green-dev ">
            <div className="container-fluid ">
                <div className="row px-4">
                    <div className="content-column col-md-7 col-sm-12 col-xs-12 ">
                        <div className="inner-content ">
                            <h2>Why We Are?...</h2>
                            <p> We have an expert Web Programmer having experience in Web Development, App Development, Web Designing And appropriate knowledge & experience of CSS3, HTML5, PHP And it's Frameworks like Yii, Laravel,Magento AND Mobile
                                Apps, Web Application & E-commerce Solutions. Combining our extensive web development skills and reasonable costing we are able to accommodate all type of client requirements from low to high budget and moderate to
                                expert web requirements.</p>
                            <ul className="list-style-one green-list ">
                                <li>Expert Developers Team</li>
                                <li>Delivery on-Time</li>
                                <li>Easy Why To Contact US</li>
                            </ul>
                            <ul className="list-style-one green-list ">
                                <li>100+ clients across the World</li>
                                <li>Affordable Rate</li>
                                <li>Complete Work With Best Way</li>
                            </ul>
                        </div>
                    </div>
                    <div className="image-column col-md-5 col-sm-12 col-xs-12 " id="agile-image ">
                        <div className="image">
                            <img src="img/banner/why_greenweblab.png " className="image-fluid " alt="digital marketing company " />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* Customer Satisfaction */}

        <section className="services-section" id="about">
            <div className="container ">
                <div className="services-title ">
                    <div className="row clearfix justify-content-center">
                        <div className="section-satisfaction-title ">
                            <h2 className="text-center ">Customer Satisfaction</h2>
                        </div>
                    </div>
                </div>
                <div className="row clearfix ">
                    <div className="services-block col-md-4 col-sm-6 col-xs-12 ">
                        <div className="inner-box">
                            <div className="icon-box ">
                                <span className="icon">
                                    <img src="img/Team/satisfaction.png" className="image-fluid " alt="digital marketing company" style={{width: 200}} />
                                </span>
                            </div>
                            <p>
                                <NavLink to="/">Work With <br/> 100% Satisfaction</NavLink>
                            </p>
                            <NavLink to="/" className="learn-more ">Know More <span
                        className="arrow fa fa-long-arrow-right "></span></NavLink>
                        </div>
                    </div>
                    <div className="services-block col-md-4 col-sm-6 col-xs-12 ">
                        <div className="inner-box">
                            <div className="icon-box ">
                                <span className="icon "><img src="img/Team/Security-2.png "style={{width: 200}} alt="Security Is Our Priority "/></span>
                            </div>
                            <p><NavLink to="/" >Security Is <br/> Our Priority</NavLink></p>
                            <NavLink to="/" className="learn-more ">Know More <span
                        className="arrow fa fa-long-arrow-right "></span></NavLink>
                        </div>
                    </div>
                    <div className="services-block col-md-4 col-sm-6 col-xs-12 ">
                        <div className="inner-box ">
                            <div className="icon-box ">
                                <span className="icon "><img src="img/Team/team-work-2.png "style={{width: 230}} alt="Team Work Goal "/></span>
                            </div>
                            <p>
                                <NavLink to="/" >Together We<br/>Achieve More</NavLink>
                            </p>
                            <NavLink to="/" className="learn-more ">Know More <span
                        className="arrow fa fa-long-arrow-right "></span></NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* Our Services */}

        <section className="offer-section ">
            <div className="container">
                <div className="section-title centered ">
                    <h2>Our Services</h2>
                </div>
                <div className="row clearfix ">
                    <div className="services-block-two col-md-3 col-sm-6 col-xs-6 ">
                        <div className="inner-box ">
                            <div className="hover-shadow-box "></div>
                            <div className="icon-box ">
                                <span className="icon">
                                    <NavLink to="/"><img src="img/Services/Yii-service.png " alt="Yii Services "/></NavLink>
                                </span>
                            </div>
                            <h3><NavLink to="/">Yii Web <br/> Development</NavLink></h3>
                            <div className="btn-box ">
                                <NavLink to="/" className="read-more">Read More </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="services-block-two col-md-3 col-sm-6 col-xs-6 ">
                        <div className="inner-box ">
                            <div className="hover-shadow-box "></div>
                            <div className="icon-box ">
                                <span className="icon">
                                    <NavLink  to="/"><img src="img/Services/laravel-service.png " alt="AI & Machine Learning "/> </NavLink>
                                </span>
                            </div>
                            <h3><NavLink to="/">Laravel Web <br/> Development</NavLink></h3>
                            <div className="btn-box ">
                                <NavLink to="/" className="read-more ">Read More</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="services-block-two col-md-3 col-sm-6 col-xs-6 ">
                        <div className="inner-box ">
                            <div className="hover-shadow-box "></div>
                            <div className="icon-box ">
                                <span className="icon">
                                    <NavLink to="/"><img src="img/Services/magento-service.png " alt="Servicenow Consultancy " /></NavLink>
                                </span>
                            </div>
                            <h3><NavLink to="/">Magento Web <br/> Development</NavLink></h3>
                            <div className="btn-box ">
                                <NavLink to="/" className="read-more ">Read More</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="services-block-two col-md-3 col-sm-6 col-xs-6 ">
                        <div className="inner-box ">
                            <div className="hover-shadow-box "></div>
                            <div className="icon-box ">
                                <span className="icon">
                                    <NavLink to="/"><img src="img/Services/php-service.png " alt="Enterprise Mobility Solutions" style={{width:225}} /></NavLink>
                                </span>
                            </div>
                            <h3><NavLink to="/">PHP Web <br/> Development</NavLink></h3>
                            <div className="btn-box ">
                                <NavLink to="/" className="read-more ">Read More</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="services-block-two col-md-3 col-sm-6 col-xs-6 ">
                        <div className="inner-box ">
                            <div className="hover-shadow-box "></div>
                            <div className="icon-box ">
                                <span className="icon">
                                    <NavLink to="/"><img src="img/Services/node-js-service.png " alt="DevOps developer "/> </NavLink>
                                </span>
                            </div>
                            <h3><NavLink to="/">NodeJs Web <br/> Development</NavLink></h3>
                            <div className="btn-box ">
                                <NavLink to="/" className="read-more ">Read More </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="services-block-two col-md-3 col-sm-6 col-xs-6 ">
                        <div className="inner-box ">
                            <div className="hover-shadow-box "></div>
                            <div className="icon-box ">
                                <span className="icon">
                                    <NavLink to="/"><img src="img/Services/react-js-service.png " alt="MVP & Prototyping " /></NavLink>
                                </span>
                            </div>
                            <h3><NavLink to="/">React JS <br/> Web Development</NavLink></h3>
                            <div className="btn-box ">
                                <NavLink to="/" className="read-more ">Read More</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className="services-block-two col-md-3 col-sm-6 col-xs-6 ">
                        <div className="inner-box ">
                            <div className="hover-shadow-box "></div>
                            <div className="icon-box ">
                                <span className="icon">
                                    <NavLink to="/"><img src="img/Services/database-service.png " alt="Open Source Technologies" /></NavLink>
                                </span>
                            </div>
                            <h3><NavLink to="/">DataBase <br/>Services</NavLink></h3>
                            <div className="btn-box ">
                                <NavLink to="/" className="read-more ">Read More</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="services-block-two col-md-3 col-sm-6 col-xs-6 ">
                        <div className="inner-box ">
                            <div className="hover-shadow-box "></div>
                            <div className="icon-box ">
                                <span className="icon">
                                    <NavLink  to="/"><img src="img/Services/angular-js-service.png " alt="React JS Development " /></NavLink>
                                </span>
                            </div>
                            <h3><NavLink to="/">Angular Js <br/> Web Development</NavLink></h3>
                            <div className="btn-box ">
                                <NavLink to="/" className="read-more ">Read More</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="services-block-two col-md-3 col-sm-6 col-xs-6 ">
                        <div className="inner-box ">
                            <div className="hover-shadow-box "></div>
                            <div className="icon-box ">
                                <span className="icon">
                                    <NavLink to="/"><img src="img/Services/flutter-service.png " alt="Node JS Development " /></NavLink>
                                </span>
                            </div>
                            <h3><NavLink to="/">Flutter <br/>Apps Development</NavLink></h3>
                            <div className="btn-box ">
                                <NavLink to="/" className="read-more ">Read More</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="services-block-two col-md-3 col-sm-6 col-xs-6 ">
                        <div className="inner-box ">
                            <div className="hover-shadow-box "></div>
                            <div className="icon-box ">
                                <span className="icon">
                                    <NavLink to="/" ><img src="img/Services/android-service.png " alt="Angular JS Development "/></NavLink>
                                </span>
                            </div>
                            <h3><NavLink to="/">Android <br/>Apps Development</NavLink></h3>
                            <div className="btn-box ">
                                <NavLink to="/" className="read-more ">Read More</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="services-block-two col-md-3 col-sm-6 col-xs-6 ">
                        <div className="inner-box ">
                            <div className="hover-shadow-box "></div>
                            <div className="icon-box ">
                                <span className="icon">
                                    <NavLink to="/" ><img src="img/Services/cloud-service.png " alt="Digital Marketing & Analytics "/></NavLink>
                                </span>
                            </div>
                            <h3><NavLink to="/">Cloud <br/> Services</NavLink></h3>
                            <div className="btn-box ">
                                <NavLink to="/" className="read-more ">Read More</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="services-block-two col-md-3 col-sm-6 col-xs-6 ">
                        <div className="inner-box ">
                            <div className="hover-shadow-box "></div>
                            <div className="icon-box ">
                                <span className="icon">
                                    <NavLink  to="/"><img src="img/Services/graphic-design-service.png " alt="CMS & E - Commerce Solutions " /></NavLink>
                                </span>
                            </div>
                            <h3><NavLink to="/">Graphic Design <br/>Development</NavLink></h3>
                            <div className="btn-box ">
                                <NavLink to="/" className="read-more ">Read More</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        {/* How Can We Help You? */}

        <section className="call-to-action-section ">
            <div className="container ">
                <div className="row clearfix ">
                    <div className="column col-md-12 col-sm-12 col-xs-12 ">
                        <h2 className="text-center ">How can we help you?</h2>
                        <p className="text-center">We understand what your business means to you, which is why we thoroughly discuss to understand your requirements considering the target audience's needs and latest market trends.</p>
                    </div>
                    <div className="col-12 col-md-4 ">
                        <div className="work-single text-center ">
                            <h4>Concept &amp; Strategies</h4>
                            <p>We initiate legitimate strategies and promising<br/> concepts tailored to your needs.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 ">
                        <div className="work-single text-center  ">
                            <h4>Design and Development</h4>
                            <p>Developing just the appropriate is our forte,<br/> Design &amp; Development complimenting requirements</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 ">
                        <div className="work-single work-single-last text-center  ">
                            <h4>Deploy</h4>
                            <p>Delivering the Perfection with earnest efforts<br/> and worthy output.</p>
                        </div>
                    </div>
                    <div className="btn-column col-md-12 col-sm-12 col-xs-12 ">
                        <NavLink to ="/ContactUs " className="theme-btn btn-style-three ">Contact Now</NavLink>
                    </div>
                </div>
            </div>
        </section>

        {/* industries serve start */}

        <div className="we-serve mt-5">
            <div className="container-fluid">
                <div className="mr-3 ml-3">
                    <div className="industries-title">
                        <h2 className=" text-center ">Industries We Serve</h2>
                    </div>
                    <div className="text-center industries-text mt-3">Perfect Blend of Innovation & Ideation Of Software For Every Industry. <br/>Promote Transparency, Mitigate Risk , Enhance Quality</div>
                    <div className="row justify-content-md-center ">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <div className="industries-box ">
                                <div className="industries-icon "><i className="fa fa-bank"></i></div>
                                <h3>Travel &amp; Hospitality</h3>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <div className="industries-box ">
                                <div className="industries-icon "><span className="fa fa-shopping-basket"></span></div>
                                <h3>Retail & E-Commerce</h3>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <div className="industries-box ">
                                <div className="industries-icon "><span className="fa fa-medkit"></span></div>
                                <h3>Healthcare</h3>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <div className="industries-box ">
                                <div className="industries-icon "><span className="fa fa-globe"></span></div>
                                <h3> Social Networking</h3>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <div className="industries-box ">
                                <div className="industries-icon "><span className="fa fa-building"></span></div>
                                <h3>Construction</h3>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <div className="industries-box ">
                                <div className="industries-icon "><span className="fa fa-truck"></span></div>
                                <h3>Logistics &amp; Distribution</h3>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <div className="industries-box ">
                                <div className="industries-icon "><span className="fa fa-book "></span></div>
                                <h3>Education &amp; e-learning</h3>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <div className="industries-box ">
                                <div className="industries-icon "><span className="fa fa-cutlery"></span></div>
                                <h3>Food &amp; Restaurant</h3>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <div className="industries-box ">
                                <div className="industries-icon "><span className="fa fa-handshake-o"></span></div>
                                <h3>Insurance</h3>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <div className="industries-box ">
                                <div className="industries-icon "><span className="fa fa-money"></span></div>
                                <h3>FinTech</h3>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <div className="industries-box ">
                                <div className=" industries-icon "><span className="fa fa-gamepad"></span></div>
                                <h3>Gaming Tool</h3>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <div className="industries-box ">
                                <div className="industries-icon "><span className="fa fa-lightbulb-o"></span></div>
                                <h3>Demand With Solutions</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="industries-conversions mt-5">
            <div className="container industries-conversions-box">
                <div className="conversions-data">
                    <p>Need above Industries Solution?</p>
                    <h2>Let's Create Conversions.</h2>
                    <p className="mb-5">With over 20 years of experience in web design and development we want to be your trusted guide the digital jungle.</p>
                    <span><NavLink to="/ContactUs">START YOUR PROJECT</NavLink></span>
                </div>
            </div>
        </div>


        {/* reach-out  */}
        <div className="reach-out mt-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                        <h1>Obtaining further 
                            <br/> information by make a 
                            <span className="reach-out-bold"><br/> Contact with our <br/> Experienced IT staffs.</span>
                        </h1>
                    </div>
                    <div className="col-sm-6 reach-out-number pt-4">
                        <h4 className="text-center">Reach Out NOW!</h4>
                        <div>
                            <a href="tel:+918799160191">
                                <h1 className="text-center reach-number mt-3"><span className="mr-2  fa fa-phone"></span>+91 8799160191</h1>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
    
)
};
export default HomePage;