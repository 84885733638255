import { ArrowRight, Check2Circle, CheckAll } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";

const WebDevAndService = () => {

    return (
        <>
            <div className= 'web_dev container-fluid p-0'>
                <div className = "web_dev_title">
                    <span>Let's Check Our Website Development   <br/>& Services.</span>
                </div>
                <hr />
                <div className = "web_des_app">
                    <div className="web_des_service text-center">
                        <span>Web Design And Development</span>
                    </div>
                    <p className="des_text"> We design innovative website to help you achieve the best outcomes your company strives for.</p>
                </div>
                <div className="web_des_section_1">
                    <div className="row align-items-center">
                        <div className="col-md-6 p-3 ">
                            <div className="image">
                                <img src="/img/Services/web-design-service.png" alt="web-app" />
                            </div>
                        </div>
                        <div className="col-md-6 p-5 ">
                            <div className="details">
                                <div className="heading">
                                    <p>Web Design And Development <span>Service</span></p>
                                </div>
                                <div className="describe">
                                    <p>Your brand can become a visual story with the assistance of web designers.We make your website a very successful marketing tool for your company and a useful way to interact with your audience.</p>
                                </div>
                                <div className="list">
                                    <ul>
                                        <li>Website designers and developers working in-house.</li>
                                        <li>Easily manage, add, and update your website's content.</li>
                                        <li>Customized features and data integrations.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className="web_des_section_2">
                    <div className="heading">
                        <span>Our Top Rated Services</span>
                    </div>
                    <div className="description">
                        <p>The quality of your website design will determine whether or not your digital business is successful. Understanding how each component of your website contributes to the overarching objective of increasing sales is crucial given the importance given on how your website looks.</p>
                    </div>
                    <div className="web_des_section_2_1">
                        <div className="all_cards">
                            <div className="row justify-content-around">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="card_data text-center">
                                        <div className="card_icon">
                                            <img width='55px' src="/img/Services/designIcon/icon_1.svg" alt="icon_1" />
                                        </div>
                                        <div className="card_title">
                                            <span>Wordpress Development</span>
                                        </div>
                                        <div className="card_description">
                                            <span>Over 35% of websites presently use the user-friendly, SEO-friendly, versatile development platform. WordPress offers a simple content management system (CMS) that makes it simple to make changes as you go.</span>
                                        </div>
                                    </div>
                                    <div className="list">
                                        <ul className="px-2">
                                            <li><Check2Circle className="arrow"/>Easy to edit</li>
                                            <li><Check2Circle className="arrow"/>Search engine friendly</li>
                                            <li><Check2Circle className="arrow"/>Highly customizable</li>
                                            <li><Check2Circle className="arrow"/>Most popular CMS in the world</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="card_data text-center">
                                        <div className="card_icon">
                                            <img width='55px' src="/img/Services/designIcon/icon_2.svg" alt="icon_1" />
                                        </div>
                                        <div className="card_title">
                                            <span>Responsive Website Design</span>
                                        </div>
                                        <div className="card_description">
                                            <span>Your website can adjust to any device it is being viewed from and offer an excellent experience thanks to responsive web design. For a user on a desktop computer, laptop, tablet, or smartphone, this means that your website will look fantastic and perform smoothly.</span>
                                        </div>
                                    </div>
                                    <div className="list">
                                        <ul className="px-2">
                                            <li><Check2Circle className="arrow"/>Mobile-friendly </li>
                                            <li><Check2Circle className="arrow"/>Design for every device</li>
                                            <li><Check2Circle className="arrow"/>Positive UX</li>
                                            <li><Check2Circle className="arrow"/>Helps SEO</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="card_data text-center">
                                        <div className="card_icon">
                                            <img width='55px' src="/img/Services/designIcon/icon_1.svg" alt="icon_1" />
                                        </div>
                                        <div className="card_title">
                                        <span>E-Commerce Website Development</span>
                                        </div>
                                        <div className="card_description">
                                            <span>A versatile eCommerce platform that offers seamless interaction with your WordPress website. WooCommerce enables you to do online sales around-the-clock while providing a wonderful client experience that is unmatched.</span>
                                        </div>
                                    </div>
                                    <div className="list">
                                        <ul className="px-2">
                                            <li><Check2Circle className="arrow"/>Built on WordPress</li>
                                            <li><Check2Circle className="arrow"/>Easy store management</li>
                                            <li><Check2Circle className="arrow"/>Ecommerce optimization</li>
                                            <li><Check2Circle className="arrow"/>Powers many sections of online stores.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )

};

export default WebDevAndService;