import { NavLink } from "react-router-dom";

const PageHead =  ({buttonTitle, buttonHref, title, details}) => {
    return (
        <>
            <div className="container-fluid p-0">
                <div className="svgTop row">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" height={50}>
                        <path fill="#14a800" d="M0,6V0h1000v100L0,6z"></path>
                    </svg>
                </div>
                <div className="container">
                    <div className='pageHead'>
                        <div className='pageHead_company_title'>
                            GreenWeblab
                        </div>
                        <div className='pageHead_head_title'>{title}</div>
                        <div className='pageHead_details'>
                            {details}
                        </div>
                        <div className="pageHead_button" >
                            <button className="btn"><NavLink to={buttonHref}>{buttonTitle}</NavLink> </button>
                        </div>

                    </div>

                </div>

                <div className="svgBottom row">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" height={50}>
                    <path fill="#57a0ac" d="M0,6V0h1000v100L0,6z"></path>
                </svg>
                </div>
            </div>      
        </>
    )

};
export default PageHead;