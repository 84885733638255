import React,{useState} from 'react';
import PageHead from "../../Components/PageHead";
import TabComponent from "../../Components/TabComponent";
import ItSolutionsServices from './ItSolutionsServices';
import SoftwareAndMobileApp from './SoftwareAndMobileApp';
import WebDevAndService from './WebDevAndService';

const topicsTabs = [
    {
      label: 'IT Solutions & Services',
      value: 'itSolutionsServices',
    },
    {
        label: 'Software & Mobile App',
        value: 'softwareMobileApp',
    },
    {
        label: 'Development & Services',
        value: 'developmentServices',
    },
    // {
    //     label: 'More Services',
    //     value: 'moreServices',
    // },
];

const Services = () => {
const details = "Our skilled professionals will manage your technology demands";

    const [viewActiveTab, setViewActiveTab] = useState(topicsTabs[0]);
    return (
        <>
        <PageHead details={details} title="Our Services" buttonTitle={'DISCUSS YOUR PROJECT'} buttonHref={'/ContactUs'}/>

        <div className='allServices'>
            <TabComponent data={topicsTabs} onChange={setViewActiveTab} selectedValue={viewActiveTab} />
        </div>

        {
        viewActiveTab.value === 'itSolutionsServices' ?
      <div className="">
        <ItSolutionsServices/>
      </div>
      : viewActiveTab.value === 'softwareMobileApp' ?
      <div className="">
        <SoftwareAndMobileApp />
      </div>
      : viewActiveTab.value === 'developmentServices' ?
      <div className="">
        <WebDevAndService />
      </div>
      : viewActiveTab.value === 'moreServices' ?
      <div className="">
        {/* {viewMeetingKindChart()} */}
      </div> : null
      }
        </>
        
    )
    };
    export default Services;